import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
  
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <FeaturesTiles /> 
      <Testimonial topDivider />
      {/* <Cta split /> */}
      
      <div class="section-header center-content">
        <div class="container-xs"><h2 class="mt-0 mb-16">Address</h2>
        <p class="m-0">Amez Technologies Inc.<br/>
        16 Windermere Court, Brampton ON L6X 2L6<br/>
        Toronto-Ontario-Canada
        </p></div></div>


    </>
  );
}

export default Home;