import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '',
    paragraph: 'Through AmeZ Tech Digital Banking, Business houses and individuals can now easily make transactions, check their account balance or even make transfers just with a single click of a button on their smartphone, desk top or any other digital device.'
  };
  const sectionHeader1 = {
    title: '',
    paragraph: 'We understand Fintech solutions as an important role in your business or your organisation. That is why Amez Tech was established to help companies and organisations like yours with world class Fintech services that are essential in growing your business, lowering your cost and increasing your efficiency through streamlining the financial process. '
  };
  const sectionHeader2 = {
    title: '',
    paragraph: 'We address the challenge of dealing with the multiple payment rails  that you require to run your business successfully without worrying of its support, implementation and the skills required.'
  };
  const sectionHeader3 = {
    title: '',
    paragraph: 'We maintain and manage all your Fintech related services to give you peace of mind and save you money.'
  };


  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  
                  </div>
                <h3 className="mt-0 mb-12">
                IT outsourcing
                  </h3>
                <p className="m-0">
                AmezTech is a Startup IT Services company in theCanada, USA and in India, delivering solutions across many industries and verticals. Powered by 50+  professionals we Discover, Define, Develop, and Deliver high-quality custom software solutions both On-Shore and Off-Shore. We have the experience and expertise to deliver unparalleled solutions for your unique business needs especially in the Financial technology area.</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/image-outsource.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                Why AmezTech?
                  </h3>
                <p className="m-0">
                From initial concept and strategic planning, to design, development and integration, we turn your business challenges into a custom-designed enterprise software solution that’s powerful, visually appealing and easy to use. Amez Tech provides turnkey services that enable companies to transform digitally, including IT consulting, custom application development, migrations, and integrations. We offer a unique blend of on-site, off-site/on-shore, off-shore application development services that save you significant time and money.  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/image-planning.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                 
                  </div>
                <h3 className="mt-0 mb-12">
                Prime Fintech Solutions
                  </h3>
                <p className="m-0">
                We provide a wide range of Fintech solutions to financial institutions aimed at improving access to financial services. Our solutions use innovative technologies such as digital payment platforms and electronic money models to enhance security, accessibility, and reliability. Our solutions include E-Wallet, Revenue Collection System and Internet Banking Solution. We provide an E-wallet solution dubbed AmezPay platform  that offers customers a better experience with security and convenience along with an easy to use user interface which is key to enhancing customer retention rate. Our revenue collection system revolves around making payments by the public to the authority easier and more efficient. With AmezTech  Internet Banking Solution, we will provide a comprehensive and secure internet banking solution enabling your customers to make transactions seamless.</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/image-fintech.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                 
                  </div>
                <h3 className="mt-0 mb-12">
              Blockchain Powered Remittance
                  </h3>
                <p className="m-0">
                Amez Tech approach of Block chain powered remittance system will reduce the stages involved in the traditional money remittance. Our system validating the transaction via the blockchain network, enabling faster payments, which is a major desire of many users.</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/image-blockchain.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


          </div>
          
          <div class="section-header center-content">

            <div class="container-xsl">
            <br/>
            <br/>
              <p class="m-0">We understand Fintech solutions as an important role in your business or your organisation. That is why Amez Tech was established to help companies and organisations like yours with world class Fintech services that are essential in growing your business, lowering your cost and increasing your efficiency through streamlining the financial process. 
              <br/>
              <br/>
              We address the challenge of dealing with the multiple payment rails  that you require to run your business successfully without worrying of its support, implementation and the skills required.
              <br/>
              <br/>
              We maintain and manage all your Fintech related services to give you peace of mind and save you money.</p>
              </div>
              </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;